import React from 'react';
import Crossword from './components/Crossword';
import './App.css';

const App = () => {
    return (
        <>
            <Crossword />
        </>
    )
}

export default App;
